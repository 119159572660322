import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import {TransitionGroup, CSSTransition} from "react-transition-group";
import {gsap, Power0, Power2, Power4} from "gsap";
import Stats from 'stats-js'

//Styles
import "./styles/normalize.css"
import 'bootstrap/dist/css/bootstrap.min.css'
import "./styles/fonts.css"
import "./styles/main.css"
//video-js
import "video.js/dist/video-js.min.css"
import "./styles/video-js.sea.css"

import {Home} from './pages/Home'
import {Chapter1} from './pages/Chapter1'
import {Chapter2} from './pages/Chapter2'
import {Chapter3} from './pages/Chapter3'
import {Chapter4} from './pages/Chapter4'
import {Chapter5} from './pages/Chapter5'

import {Pravi} from './components/Pravi'
import Cursor from './components/Cursor'

const routes = [
  { path: '/', name: 'Главная', Component: Home },
  { path: '/chapter1', name: 'Глава 1', Component: Chapter1 },
  { path: '/chapter2', name: 'Глава 2', Component: Chapter2 },
  { path: '/chapter3', name: 'Глава 3', Component: Chapter3 },
  { path: '/chapter4', name: 'Глава 4', Component: Chapter4 },
  { path: '/chapter5', name: 'Глава 5', Component: Chapter5 },
]

class App extends Component {

  constructor() {
    super();
    this.pravi = React.createRef();
    this.cursor = React.createRef();
  }

  componentDidMount() {

    //STATS
    var stats = new Stats();
    stats.showPanel( 0 ); // 0: fps, 1: ms, 2: mb, 3+: custom
    document.body.appendChild( stats.dom );

    function animate() {

        stats.begin();

        // monitored code goes here

        stats.end();

        requestAnimationFrame( animate );

    }

    requestAnimationFrame( animate );

      // Always start from begin!
      window.scrollTo(0,0);
      // Indeed window position top?
      setTimeout(function (){
          window.scrollTo(0,0);
      }, 250);

      let tl = gsap.timeline(),
          specialProject = document.querySelector('#mainPravi #specialProject'),
          menuBurger = document.querySelector('#mainPravi #menuBurger'),
          chapterName = document.querySelector('#mainPravi #chapterName');

      if(this.props.location.pathname == '/'){
          tl.to(specialProject, .5, {opacity: 1, y: 0, visibility: 'visible'}, 0)
              .to(chapterName, .5, {opacity: 0, visibility: 'hidden'}, 0)
              .to(menuBurger, .5, {opacity: 0, visibility: 'hidden'}, 0)

      } else {
          tl.to(specialProject, .5, {opacity: 0, visibility: 'hidden'}, 0)
              .to(chapterName, .5, {opacity: 0, visibility: 'hidden'}, 0)
              .to(menuBurger, .5, {opacity: 1, visibility: 'visible'}, 0)
      }

  }

  componentDidUpdate(prevProps) {

    if (this.props.location.pathname !== prevProps.location.pathname) {

      this.cursor.current.forceUpdateHandler();

    }

    let tl = gsap.timeline(),
        specialProject = document.querySelector('#mainPravi #specialProject'),
        menuBurger = document.querySelector('#mainPravi #menuBurger'),
        chapterName = document.querySelector('#mainPravi #chapterName');

    if(this.props.location.pathname == '/'){
        tl.to(specialProject, .5, {opacity: 1, y: 0, visibility: 'visible'}, 0)
            .to(chapterName, .5, {opacity: 0, visibility: 'hidden'}, 0)
            .to(menuBurger, .5, {opacity: 0, visibility: 'hidden'}, 0)
            .to('#mainPravi svg path, #mainPravi svg polygon', .5, {fill: '#fff'}, 1)
            .to('#mainPravi .nav-link', .5, {color: '#fff'}, 1)
            .to('#circleCursor', .25, {backgroundColor: '#fff'})
            .to('#circleCursor svg path', .25, {fill: '#094F95'})
    } else {
        tl.to(specialProject, .5, {opacity: 0, visibility: 'hidden'}, 0)
            .to(chapterName, .5, {opacity: 0, visibility: 'hidden'}, 0)
            .to(menuBurger, .5, {opacity: 1, visibility: 'visible'}, 0)
            .to('#mainPravi svg path, #mainPravi svg polygon', .5, {fill: '#fff'}, 1)
            .to('#mainPravi .nav-link', .5, {color: '#fff'}, 1)
            .to('#circleCursor', .25, {backgroundColor: '#fff'})
            .to('#circleCursor svg path', .25, {fill: '#094F95'})
    }

  }

  render() {

    const timeout = { enter: 1000, exit: 1000 };

    return (
        <Route render={({location}) => (
            <main>
              <div id="preloader"></div>
              <Pravi ref={this.pravi} />
              <TransitionGroup component="div" className="fadePage">
                <CSSTransition
                    key={location.key}
                    timeout={timeout}
                    classNames="fade"
                    onEntering={
                      e => {
                        document.body.classList.add('loading');
                        document.addEventListener('wheel', this.preventDefault);

                        // Animation for slider on home page
                        if(this.props.location.pathname != '/'){

                            let tl = gsap.timeline();

                            tl.to('#homeSlider .slide.active .imageWrapper', 0, {opacity: 1}, 0);
                            tl.to('#homeSlider .slide.active .canvasWrapper', 0, {opacity: 0}, 0);
                            // tl.fromTo('#homeSlider .slide.active .imageWrapper .plane1', 1, {yPercent: 0}, {yPercent: 150, ease: Power2.easeInOut}, 0);
                            // tl.fromTo('#homeSlider .slide.active .imageWrapper .plane2', 1, {yPercent: -50, xPercent: -50}, {yPercent: -200, xPercent: -50, ease: Power2.easeInOut}, 0);
                            // tl.fromTo('#homeSlider .slide.active .imageWrapper .plane3', 1, {yPercent: 0}, {yPercent: 150, ease: Power2.easeInOut}, 0);

                        }

                        setTimeout(function (){
                            window.scrollTo(0, 0);
                        }, 1000);
                      }
                    }
                    onEnter={
                      this.pageFadeOut
                    }
                    onExited={
                      this.pageFadeIn,
                      e => {
                        document.body.classList.remove('loading');
                        document.removeEventListener('wheel', this.preventDefault);
                    }
                    }
                    onExit={e => {
                      //window.scrollTo(0, 0);
                    }}
                >
                  <Switch location={location}>

                    <Route path='/' exact component={Home} />

                    <Route path='/chapter1' exact component={Chapter1} />

                    <Route path='/chapter2' exact component={Chapter2} />

                    <Route path='/chapter3' exact component={Chapter3} />

                    <Route path='/chapter4' exact component={Chapter4} />

                    <Route path='/chapter5' exact component={Chapter5} />

                  </Switch>
                </CSSTransition>
              </TransitionGroup>
              <Cursor ref={this.cursor} />
            </main>

        )} />
    );

  }

}

export default App;