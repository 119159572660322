import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Tween } from 'react-gsap';
import {gsap} from "gsap";

const MouseFollowStyled = styled.div`
`;

const Circle = styled.div`
  position: fixed;
  background-color: #fff;
  width: 80px;
  height: 80px;
  left:-40px;
  top:-40px;
  z-index: 1;
  user-select: none;
  pointer-events: none;
  z-index: 10000;
  transform: scale(.1875);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
`;

class MouseFollowComponent extends PureComponent {

    state = {
        x: window.innerWidth/2,
        y: window.innerHeight/2,
        scaleCircle: .1875,
        reload: false
    }

    tween;

    constructor(props) {
        super(props);
        this.mouseMove = this.mouseMove.bind(this);
        this.hoverFunc = this.hoverFunc.bind(this);
        this.unhoverFunc = this.unhoverFunc.bind(this);
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
    }

    forceUpdateHandler(){

        this.componentDidMount();

    };

    componentDidMount() {

        window.addEventListener('mousemove', this.mouseMove);

        let hoverables = document.querySelectorAll('a, .hoverable');
        for (let i=0; i < hoverables.length; i++){
            if(!hoverables[i].classList.contains('unhoverable')){
                hoverables[i].addEventListener('mouseenter', this.hoverFunc);
                hoverables[i].addEventListener('mouseleave', this.unhoverFunc);
            }
        }

    }

    componentWillUnmount() {
        window.removeEventListener('mousemove', this.mouseMove);
    }

    mouseMove({ clientX: x, clientY: y }) {
        this.setState((state) => {
            return {x, y};
        });
    }

    hoverFunc(event){

        gsap.to('#circleCursor', .5, {scale:1});

        if(event.target.classList.contains('cursorDown')){
            gsap.to("#cursorDown", .5, {opacity: 1});
        } else if(event.target.classList.contains('cursorHome')){
            gsap.to("#cursorHome", .5, {opacity: 1});
        } else if(event.target.classList.contains('cursorClose')){
            gsap.to("#cursorClose", .5, {opacity: 1});
        } else if(event.target.classList.contains('cursorLeft')){
            gsap.to("#cursorLeft", .5, {opacity: 1});
        } else if(event.target.classList.contains('cursorUp')){
            gsap.to("#cursorUp", .5, {opacity: 1});
        } else {
            gsap.to("#cursorEye", .5, {opacity: 1});
        }

    }

    unhoverFunc(event){

        gsap.to('#circleCursor', .5, {scale:.1875});
        gsap.to("#cursorEye, #cursorHome, #cursorUp, #cursorDown, #cursorLeft, #cursorClose", .5, {opacity: 0});

    }

    render() {
        const { x, y } = this.state;

        return(
            <MouseFollowStyled className="circleCursor">
                <Tween to={{x: x, y: y}} duration={0.1} ref={ref => this.tween = ref}>
                    <Circle id="circleCursor" className={'blue ' + this.props.update ? 'test' : 'test2'}>

                        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">

                            <path id="cursorLeft" style={{opacity: 0}} fillRule="evenodd" clipRule="evenodd" d="M35.9568 49.5936L31.8659 46.8663C28.9568 44.9027 26.9977 43.4208 26.0022 42.4208C25.3113 41.7299 24.9477 41.0572 24.734 40.3936H62.8613L64.2295 38.6163H24.734C24.9477 37.9481 25.3113 37.2754 26.0022 36.5845C26.9977 35.5845 28.9568 34.1027 31.8659 32.139L35.9568 29.4118V27.7754L15.2249 39.2299V39.7754L35.9568 51.2299V49.5936Z" fill="#094F95"/>
                            <path id="cursorDown" style={{opacity: 0}} fillRule="evenodd" clipRule="evenodd" d="M49.8182 43.2732L47.0909 47.3641C45.1273 50.2732 43.6455 52.2323 42.6455 53.2278C41.9545 53.9187 41.2818 54.2823 40.6182 54.4959V16.3687L38.8409 15.0005V54.4959C38.1727 54.2823 37.5 53.9187 36.8091 53.2278C35.8091 52.2323 34.3273 50.2732 32.3636 47.3641L29.6364 43.2732H28L39.4545 64.005H40L51.4545 43.2732H49.8182Z" fill="#094F95"/>
                            <path id="cursorUp" style={{opacity: 0}} fillRule="evenodd" clipRule="evenodd" d="M30.4068 35.9573L33.1341 31.8663C35.0977 28.9573 36.5795 26.9982 37.5795 26.0027C38.2704 25.3118 38.9432 24.9482 39.6068 24.7345L39.6068 62.8618L41.3841 64.23L41.3841 24.7345C42.0522 24.9482 42.725 25.3118 43.4159 26.0027C44.4159 26.9982 45.8977 28.9573 47.8613 31.8663L50.5886 35.9573H52.225L40.7704 15.2254H40.225L28.7704 35.9573H30.4068Z" fill="#094F95"/>
                            <g id="cursorClose" style={{opacity: 0}}>
                                <path fillRule="evenodd" clipRule="evenodd" d="M48.6113 47.3515C47.0553 45.8145 45.5353 42.6295 45.5353 40.0005C45.5353 37.3885 47.0553 34.2025 48.6113 32.6475C48.6113 32.6475 48.5233 32.7345 48.3823 32.8735L55.8313 25.4245L54.5743 24.1675L47.2263 31.5165C47.2993 31.4425 47.3513 31.3895 47.3513 31.3895C45.7973 32.9465 42.6103 34.4665 39.9983 34.4665C37.3713 34.4665 34.1893 32.9485 32.6503 31.3925L25.4253 24.1675L24.1693 25.4245L31.4373 32.6935C31.4173 32.6725 31.3903 32.6475 31.3903 32.6475C32.9473 34.1845 34.4663 37.3695 34.4663 39.9975C34.4663 42.6105 32.9473 45.7965 31.3903 47.3515C31.3903 47.3515 31.4173 47.3255 31.4413 47.3015L24.1693 54.5745L25.4253 55.8305L32.6503 48.6065C34.1893 47.0505 37.3713 45.5325 39.9983 45.5325C42.6103 45.5325 45.7973 47.0525 47.3513 48.6095C47.3513 48.6095 47.2993 48.5565 47.2263 48.4825L54.5743 55.8305L55.8313 54.5745L48.3993 47.1415C48.5283 47.2685 48.6113 47.3515 48.6113 47.3515" fill="#094F95"/>
                            </g>
                            <g id="cursorEye" style={{opacity: 0}}>
                                <path fillRule="evenodd" clipRule="evenodd" d="M34.3335 40C34.3335 36.975 36.7865 34.521 39.8125 34.521C42.8385 34.521 45.2915 36.975 45.2915 40C45.2915 43.026 42.8385 45.479 39.8125 45.479C36.7865 45.479 34.3335 43.026 34.3335 40Z" stroke="#005094" fill="#005094" strokeWidth="2"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M54.168 48.6456L54.164 48.6476C49.243 51.5306 44.414 52.9916 39.811 52.9916C35.192 52.9916 30.347 51.5206 25.41 48.6206C21.833 46.2866 19.865 43.2256 19.865 39.9996C19.865 36.7546 21.854 33.6806 25.465 31.3426C30.384 28.4646 35.21 27.0076 39.811 27.0076C44.41 27.0076 49.237 28.4676 54.16 31.3496C57.765 33.6876 59.747 36.7586 59.747 39.9996C59.747 43.2406 57.766 46.3106 54.168 48.6456ZM66.712 39.4506C66.683 39.4156 63.742 35.9366 58.876 32.3916C54.438 29.1606 47.527 25.3076 39.811 25.3076C32.092 25.3076 25.183 29.1606 20.746 32.3916C15.937 35.8946 13.03 39.3066 12.909 39.4506L12.442 39.9996L12.909 40.5506C13.032 40.6956 15.987 44.1416 20.746 47.6086C25.183 50.8396 32.092 54.6926 39.811 54.6926C47.527 54.6926 54.438 50.8396 58.876 47.6086C63.693 44.0986 66.682 40.5856 66.712 40.5506L67.18 39.9996L66.712 39.4506Z" fill="#005094"/>
                            </g>
                            <g id="cursorHome" style={{opacity: 0}}>
                                <path fillRule="evenodd" clipRule="evenodd" d="M52.2735 55.7727H45.3385V39.4517H34.6605V55.7727H27.7255V33.5797L28.3295 33.0077C31.4415 30.0987 33.7905 28.1397 35.3755 27.1447C36.4695 26.4537 38.2035 25.6847 40.0005 25.6847C41.7955 25.6847 43.5295 26.4537 44.6255 27.1447C46.2105 28.1397 48.5575 30.0987 51.6695 33.0077L52.2735 33.5797V55.7727ZM36.3595 55.7727H43.6395V41.1507H36.3595V55.7727ZM58.5855 37.0987L40.4315 16.3677H39.5675L21.4135 37.0987H24.0065L26.0265 35.1877V57.4717H53.9725V35.1877L55.9925 37.0987H58.5855Z" fill="#094F95"/>
                            </g>

                        </svg>

                    </Circle>
                </Tween>
            </MouseFollowStyled>
        );
    }
}

export default MouseFollowComponent;