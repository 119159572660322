import React, {Component} from 'react'
import { NavLink } from 'react-router-dom'
import {Container, Row, Col} from 'react-bootstrap'
import {Curtains, Plane} from "curtainsjs"
import {gsap, Power2, Power4} from "gsap"
import {SplitText} from "gsap/SplitText"

export class Home extends Component {

    constructor(props) {
        super(props);
        // reference to the DOM node
        this.goNextOperation = this.goNextOperation.bind(this);
        this.loadHome = this.loadHome.bind(this);
        this.checkSlideReady = this.checkSlideReady.bind(this);
        this.seaPlane = [];
        this.planes = [];
        this.slideReady = 1;
        this.state = { slideReady: 1 };
        this.webGLCurtainsImages = [];
        this.webGLSeaImage = [];
    }

    componentDidMount() {

        document.body.classList.add('home');
        document.body.classList.remove('chapter1','chapter2','chapter3','chapter4','chapter5');

        const myThis = this;

        window.addEventListener("load", myThis.loadHome);

        //myThis.loadHome();

        document.getElementById('about').addEventListener('click', function (e){

            e.preventDefault();

            myThis.goAbout();

        });

        document.getElementById('aboutClose').addEventListener('click', function (e){

            e.preventDefault();

            myThis.goAbout();

        });

        // Unmount all Events in the right time
        let anchorLinks = document.querySelectorAll('a');

        anchorLinks.forEach(function (anchor) {

            anchor.addEventListener('click', function (e) {

                myThis.webGLSeaImage.disableDrawing();
                myThis.webGLSeaImage.dispose();

                myThis.webGLCurtainsImages.forEach(function (curtain){

                    curtain.disableDrawing();
                    curtain.dispose();

                });

                /*e.preventDefault();

                let tl = gsap.timeline();

                tl.to('#homeSlider .slide.active .imageWrapper', 0, {opacity: 1}, 0);
                tl.to('#homeSlider .slide.active .canvasWrapper', 0, {opacity: 0, onComplete: function (){
                        myThis.webGLSeaImage.disableDrawing();
                        myThis.webGLSeaImage.dispose();

                        myThis.webGLCurtainsImages.forEach(function (curtain){

                            curtain.disableDrawing();
                            curtain.dispose();

                        });
                    }}, 0);
                tl.fromTo('#homeSlider .slide.active .imageWrapper .plane1', 1, {yPercent: 0}, {yPercent: 150, ease: Power2.easeInOut}, 0);
                tl.fromTo('#homeSlider .slide.active .imageWrapper .plane2', 1, {yPercent: -50, xPercent: -50}, {yPercent: -200, xPercent: -50, ease: Power2.easeInOut}, 0);
                tl.fromTo('#homeSlider .slide.active .imageWrapper .plane3', 1, {yPercent: 0}, {yPercent: 150, ease: Power2.easeInOut}, 0);

                setTimeout(function (){
                    myThis.props.history.push('/chapter4')
                }, 1000)*/

            });

        });

    }

    goAbout(){

        let tl = gsap.timeline();

        let aboutButton = document.querySelector('#about');

        if(aboutButton.classList.contains('active')){

            aboutButton.classList.remove('active');
            document.body.classList.remove('aboutOpened');

            tl.fromTo('#aboutMenu .rightMenu', {xPercent: 0, opacity: 1}, {xPercent: 200, opacity: 0, duration: 1}, "0")
                .fromTo('#aboutMenu .leftMenu', {xPercent: 0, opacity: 1}, {xPercent: 200, opacity: 0, duration: 1}, "-=0.75")
                .fromTo('#aboutBg', {xPercent: 0}, {xPercent: 100, duration: 1}, "-=0.65")
                .fromTo('#aboutMenu', {opacity: 1, visibility: 'visible'}, {opacity: 0, visibility: 'hidden', duration: 0.1}, "+=0")
                .fromTo('#aboutMenu #aboutCopy', .5,{x: 0, opacity: 1}, {x: 100, opacity: 0}, .75)
                .fromTo('#aboutMenu #aboutClose', .5,{x: -60, opacity: 1}, {x: 40, opacity: 0}, 0)
        }else{

            aboutButton.classList.add('active');
            document.body.classList.add('aboutOpened');

            tl.fromTo('#aboutMenu', {opacity: 0, visibility: 'hidden'}, {opacity: 1, visibility: 'visible', duration: 0}, "0")
                .fromTo('#aboutBg', {xPercent: 100}, {xPercent: 0, duration: 1}, "0")
                .fromTo('#aboutMenu .leftMenu', {xPercent: 100, opacity: 0}, {xPercent: 0, opacity: 1}, "-=0.5")
                .fromTo('#aboutMenu .rightMenu', {xPercent: 100, opacity: 0}, {xPercent: 0, opacity: 1}, "-=0.25")
                .fromTo('#aboutMenu #aboutCopy', .5,{x: 100, opacity: 0}, {x: 0, opacity: 1}, .25)
                .fromTo('#aboutMenu #aboutClose', .5,{x: 40, opacity: 0}, {x: -60, opacity: 1}, 1)

        }

    }

    goSea(){

        const myThis = this;

        // set up our WebGL context and append the canvas to our wrapper
        myThis.webGLSeaImage = new Curtains({
            container: "canvasSea",
            watchScroll: false,
            antialias: true,
            pixelRatio: Math.min(.75, window.devicePixelRatio) // limit pixel ratio for performance
        });
        // get our plane element
        const planeElement = document.getElementsByClassName("planeSea")[0];

        // all planes will have the same parameters
        const params = {
            vertexShader: myThis.vertShader(),
            fragmentShader: myThis.fragShader(),
            widthSegments: 20,
            heightSegments: 20,
            fov: 10,
            uniforms: {
                time: {
                    name: "uTime", // uniform name that will be passed to our shaders
                    type: "1f", // this means our uniform is a float
                    value: 0,
                },
            }
        };

        // create our plane using our curtains object, the HTML element and the parameters
        this.seaPlane = new Plane(myThis.webGLSeaImage, planeElement, params);
        this.seaPlane.onRender(() => {
            // use the onRender method of our plane fired at each requestAnimationFrame call
            this.seaPlane.uniforms.time.value++; // update our time uniform value
        });

    }

    loadHome(){

        const myThis = this;

        setTimeout(function (){
            document.body.classList.remove('loading');
        }, 1000);

        //Split Home Title
        let splitHomeTitle = new SplitText('#slide1 .splitTitle span', {type: "chars"});

        let TL = gsap.timeline();

        TL.from(splitHomeTitle.chars,1, {yPercent: 120, opacity: 0, stagger: 0.02, force3D: false, onComplete: function (){

            myThis.goSea();

            }}, 0)
            .to('#mainPravi svg path, #mainPravi svg polygon', .5, {fill: '#fff'}, 0)
            .to('#mainPravi .nav-link', .5, {color: '#fff'}, 0)
            .to('#circleCursor', .25, {backgroundColor: '#FFF'}, 0)
            .to('#circleCursor svg path', .25, {fill: '#094F95'}, 0);

        window.addEventListener('wheel', this.checkSlideReady);

    }

    checkSlideReady = e => {

        const myThis = this;

        if(this.state.slideReady === 1){

            myThis.state.slideReady = 0;

            myThis.goNextOperation(Math.sign(e.deltaY));

            // Unset calldawn
            setTimeout(function () {

                myThis.state.slideReady = 1;

            }, 5000);

        } else {

            return false;

        }

    };

    // Slides Images
    goPlanesImages(index){

        const myThis = this;

        if(!myThis.webGLCurtainsImages[index] || myThis.webGLCurtainsImages[index].length == 0){

            myThis.webGLCurtainsImages[index] = new Curtains({
                container: "canvasImages" + index,
                watchScroll: false,
                antialias: true,
                pixelRatio: Math.min(.75, window.devicePixelRatio) // limit pixel ratio for performance
            });

            let imagesParams = {
                vertexShader: myThis.vertShader(),
                fragmentShader: myThis.fragShader(),
                widthSegments: 30,
                heightSegments: 20,
                fov: 0,
                uniforms: {
                    time: {
                        name: "uTime", // uniform name that will be passed to our shaders
                        type: "1f", // this means our uniform is a float
                        value: 0,
                    },
                }
            };

            let planeImages = document.querySelectorAll("#planeImages" + index + " .plane");

            myThis.planes[index] = [];

            // if we got planes to add
            if(planeImages.length > 0) {

                for(let i = 0; i < planeImages.length; i++) {

                    myThis.planes[index].push(new Plane(myThis.webGLCurtainsImages[index], planeImages[i], imagesParams));

                }

            }

        } else {

            myThis.webGLCurtainsImages[index].enableDrawing();

        }

    }

    goNextOperation(direction){

        let myThis = this;

        if(typeof direction === 'object'){
            direction = 1;
        }

        let slides = document.querySelectorAll('#homeSlider .slide'),
            slidesNum = slides.length,
            step = direction,
            currentSlide = document.querySelector('#homeSlider .active'),
            currentSlideNum = parseInt(currentSlide.id.replace( /^\D+/g, '')),
            nextSlideNum = currentSlideNum + step;

        if(nextSlideNum > slidesNum){
            nextSlideNum = 1;
        } else if(nextSlideNum < 1){
            nextSlideNum = slidesNum;
        }

        let nextSlide = document.getElementById('slide' + nextSlideNum);

        let TL = gsap.timeline();

        let splitCurrentTitle = new SplitText('#slide' + currentSlideNum + ' .splitTitle span', {type: "chars"});
        let splitNextTitle = new SplitText('#slide' + nextSlideNum + ' .splitTitle span', {type: "chars"});

        TL.to(currentSlide, 0, {zIndex: 1}, 0);
        TL.to(nextSlide, 0, {zIndex: 0, onComplete: function (){nextSlide.classList.add('active');}}, 0);
        //Slider line
        TL.to('#homeSliderPravi #sliderLine span', 1.5, {height: 0, ease: Power2.easeInOut, onComplete: function (){
            document.querySelector('#sliderNextNumber').innerHTML = nextSlideNum - 1;
            let slTL = gsap.timeline();
                slTL.fromTo('#sliderNextNumber', 1, {opacity: 0, y: -10}, {opacity: 1, y: 0}, 0)
                slTL.fromTo('#sliderCurrentNumber', 1, {opacity: 1, y: 0}, {opacity: 0, y: 10, onComplete: function (){
                    document.querySelector('#sliderCurrentNumber').innerHTML = nextSlideNum - 1;
                    slTL.clear();
                }}, 0)
            }}, 0);
        TL.to('#homeSliderPravi #sliderLine span', 1.5, {height: 90, ease: Power2.easeInOut}, 1.5);
        //EOF. Slider line
        TL.fromTo('#slide' + currentSlideNum + ' .slideBg', 2,{yPercent: 0},{yPercent: -100, force3D: false, ease: Power4.easeInOut}, 0.75);
        TL.fromTo(splitCurrentTitle.chars,1, {yPercent: 0, opacity: 1},{yPercent: 120, opacity: 0, force3D: false, stagger: currentSlideNum == 1 ? 0.01 : 0.03}, 0);
        TL.fromTo(splitNextTitle.chars,1, {yPercent: -120, opacity: 0},{yPercent: 0, opacity: 1, force3D: false, stagger: nextSlideNum == 1 ? 0.01 : 0.03, onComplete: function (){
            currentSlide.classList.remove('active');
            currentSlide.style.zIndex = 0;
            splitCurrentTitle.revert();
            nextSlide.style.zIndex = 1;
            gsap.to('#slide' + currentSlideNum + ' .slideBg',0, {yPercent: 0});
        }}, 1.5);

        if(currentSlideNum != 1){

            TL.to('#slide' + currentSlideNum + ' .imageWrapper', 0, {opacity: 1, onComplete: function (){

                myThis.webGLCurtainsImages[currentSlideNum].disableDrawing();

            }}, 0);
            TL.to('#slide' + currentSlideNum + ' .canvasWrapper', 0, {opacity: 0}, 0);
            TL.fromTo('#slide' + currentSlideNum + ' .imageWrapper .plane1', 2, {yPercent: 0}, {yPercent: 150, force3D: false, ease: Power4.easeInOut}, 0);
            TL.fromTo('#slide' + currentSlideNum + ' .imageWrapper .plane2', 2, {yPercent: -50, xPercent: -50}, {yPercent: -200, force3D: false, xPercent: -50, ease: Power4.easeInOut}, 0);
            TL.fromTo('#slide' + currentSlideNum + ' .imageWrapper .plane3', 2, {yPercent: 0}, {yPercent: 150, force3D: false, ease: Power4.easeInOut}, 0);

        } else {

            myThis.webGLSeaImage.disableDrawing();

        }

        if(nextSlideNum != 1){

            TL.to('#slide' + nextSlideNum + ' .imageWrapper', 0, {opacity: 1}, 0);
            TL.to('#slide' + nextSlideNum + ' .canvasWrapper', 0, {opacity: 0, onComplete: function (){

            }}, 0);
            TL.fromTo('#slide' + nextSlideNum + ' .imageWrapper .plane1', 2, {yPercent: -100}, {yPercent: 0, force3D: false, ease: Power4.easeInOut}, 1);
            TL.fromTo('#slide' + nextSlideNum + ' .imageWrapper .plane2', 2, {yPercent: 100, xPercent: -50}, {yPercent: -50, xPercent: -50, force3D: false, ease: Power4.easeInOut}, 1);
            TL.fromTo('#slide' + nextSlideNum + ' .imageWrapper .plane3', 2, {yPercent: -100}, {yPercent: 0, force3D: false, ease: Power4.easeInOut}, 1);
            TL.to('#slide' + nextSlideNum + ' .imageWrapper', 0, {onComplete: function (){

                    myThis.goPlanesImages(nextSlideNum);

                    let perspective = 1;

                    myThis.planes[nextSlideNum].forEach(function (planeItem){

                        let myInterval = setInterval(function (){
                            planeItem.setPerspective(perspective);
                            perspective++;
                            if(perspective > 10){
                                clearInterval(myInterval);
                            }
                        }, 50);

                        // check if our plane is defined and use it
                        planeItem.onRender(() => {
                            planeItem.uniforms.time.value++;
                        });

                    })

                    setTimeout(function (){
                        gsap.to('#slide' + nextSlideNum + ' .imageWrapper', 0, {opacity: 0});
                        gsap.to('#slide' + nextSlideNum + ' .canvasWrapper', 0, {opacity: 0.5});
                    }, 500)

                }}, 3);

        } else {

            myThis.webGLSeaImage.enableDrawing();

            // myThis.seaPlane.setPerspective(10);
            //
            // myThis.seaPlane.onRender(() => {
            //     // use the onRender method of our plane fired at each requestAnimationFrame call
            //     myThis.seaPlane.uniforms.time.value++; // update our time uniform value
            // });

        }

        //Forward Direction
        if(currentSlideNum == 6 && nextSlideNum == 1) {

            TL.fromTo('#mainPravi #specialProject', 1, {opacity: 0, y: -20}, {opacity: 1, y: 0}, 1);
            TL.fromTo('#mainPravi #chapterName', 1, {opacity: 1, y: -20, visibility: "visible"}, {opacity: 0, y: 0, visibility: "hidden"}, 1);
            TL.fromTo('#mainPravi #chapterName #chapterNumber5', 1, {opacity: 1, y: -12}, {opacity: 0, y: 0}, 1);

        } else if(currentSlideNum == 1 && nextSlideNum == 2){

            TL.fromTo('#mainPravi #specialProject', 1, {opacity: 1, y: 0}, {opacity: 0, y: -20}, 1);
            TL.fromTo('#mainPravi #chapterName', 1, {opacity: 0, y: 0, visibility: "hidden"}, {opacity: 1, y: -20, visibility: "visible"}, 1);
            TL.fromTo('#mainPravi #chapterName #chapterNumber1', 1, {opacity: 0, y: 0}, {opacity: 1, y: -12}, 1.5);

        } else if(currentSlideNum == 2 && nextSlideNum == 3){

            TL.fromTo('#mainPravi #chapterName #chapterNumber1', 1, {opacity: 1, y: -12}, {opacity: 0, y: -24}, 1.5);
            TL.fromTo('#mainPravi #chapterName #chapterNumber2', 1, {opacity: 0, y: 0}, {opacity: 1, y: -12}, 1.5);

        } else if(currentSlideNum == 3 && nextSlideNum == 4){

            TL.fromTo('#mainPravi #chapterName #chapterNumber2', 1, {opacity: 1, y: -12}, {opacity: 0, y: -24}, 1.5);
            TL.fromTo('#mainPravi #chapterName #chapterNumber3', 1, {opacity: 0, y: 0}, {opacity: 1, y: -12}, 1.5);

        } else if(currentSlideNum == 4 && nextSlideNum == 5){

            TL.fromTo('#mainPravi #chapterName #chapterNumber3', 1, {opacity: 1, y: -12}, {opacity: 0, y: -24}, 1.5);
            TL.fromTo('#mainPravi #chapterName #chapterNumber4', 1, {opacity: 0, y: 0}, {opacity: 1, y: -12}, 1.5);

        } else if(currentSlideNum == 5 && nextSlideNum == 6){

            TL.fromTo('#mainPravi #chapterName #chapterNumber4', 1, {opacity: 1, y: -12}, {opacity: 0, y: -24}, 1.5);
            TL.fromTo('#mainPravi #chapterName #chapterNumber5', 1, {opacity: 0, y: 0}, {opacity: 1, y: -12}, 1.5);

        }

        //Back direction
        if(currentSlideNum == 1 && nextSlideNum == 6) {

            TL.fromTo('#mainPravi #specialProject', 1, {opacity: 1, y: 0}, {opacity: 0, y: -20}, 1);
            TL.fromTo('#mainPravi #chapterName', 1, {opacity: 0, y: 0, visibility: "hidden"}, {opacity: 1, y: -20, visibility: "visible"}, 1);
            TL.fromTo('#mainPravi #chapterName #chapterNumber5', 1, {opacity: 0, y: 0}, {opacity: 1, y: -12}, 1.5);

        } else if(currentSlideNum == 6 && nextSlideNum == 5){

            TL.fromTo('#mainPravi #chapterName #chapterNumber5', 1, {opacity: 1, y: -12},{opacity: 0, y: -24}, 1.5);
            TL.fromTo('#mainPravi #chapterName #chapterNumber4', 1, {opacity: 0, y: 0}, {opacity: 1, y: -12}, 1.5);

        } else if(currentSlideNum == 5 && nextSlideNum == 4){

            TL.fromTo('#mainPravi #chapterName #chapterNumber4', 1, {opacity: 1, y: -12},{opacity: 0, y: -24}, 1.5);
            TL.fromTo('#mainPravi #chapterName #chapterNumber3', 1, {opacity: 0, y: 0}, {opacity: 1, y: -12}, 1.5);

        } else if(currentSlideNum == 4 && nextSlideNum == 3){

            TL.fromTo('#mainPravi #chapterName #chapterNumber3', 1, {opacity: 1, y: -12},{opacity: 0, y: -24}, 1.5);
            TL.fromTo('#mainPravi #chapterName #chapterNumber2', 1, {opacity: 0, y: 0}, {opacity: 1, y: -12}, 1.5);

        } else if(currentSlideNum == 3 && nextSlideNum == 2){

            TL.fromTo('#mainPravi #chapterName #chapterNumber2', 1, {opacity: 1, y: -12},{opacity: 0, y: -24}, 1.5);
            TL.fromTo('#mainPravi #chapterName #chapterNumber1', 1, {opacity: 0, y: 0}, {opacity: 1, y: -12}, 1.5);

        } else if(currentSlideNum == 2 && nextSlideNum == 1){

            TL.fromTo('#mainPravi #specialProject', 1, {opacity: 0, y: -20}, {opacity: 1, y: 0}, 1);
            TL.fromTo('#mainPravi #chapterName', 1, {opacity: 1, y: -20, visibility: "visible"}, {opacity: 0, y: 0, visibility: "hidden"}, 1);
            TL.fromTo('#mainPravi #chapterName #chapterNumber1', 1, {opacity: 1, y: -12}, {opacity: 0, y: 0}, 1);

        }

    }

    componentWillUnmount() {

        window.removeEventListener("load", this.loadHome);
        window.removeEventListener('wheel', this.checkSlideReady);

        // this.webGLSeaImage.disableDrawing();
        //
        // this.webGLCurtainsImages.forEach(function (curtain){
        //
        //     curtain.disableDrawing();
        //
        // });
        //
        // this.seaPlane = [];
        // this.planes = [];

    }

    fragShader(){

        return (`
                        
            precision mediump float;
            varying vec3 vVertexPosition;
            varying vec2 vTextureCoord;
            uniform sampler2D uSampler0;
            void main( void ) {
                // our texture
                gl_FragColor = texture2D(uSampler0, vTextureCoord);
            }
	    
        `);

    }

    vertShader(){

        return (`
                        
            precision mediump float;
            // default mandatory variables
            attribute vec3 aVertexPosition;
            attribute vec2 aTextureCoord;
            uniform mat4 uMVMatrix;
            uniform mat4 uPMatrix;
            // texture matrix
            uniform mat4 uTextureMatrix0;
            // custom variables
            varying vec3 vVertexPosition;
            varying vec2 vTextureCoord;
            uniform float uTime;
            void main() {
                vec3 vertexPosition = aVertexPosition;
                float distanceFromCenter = distance(vec2(vertexPosition.x, vertexPosition.y), vec2(0.5, vertexPosition.x));
                vertexPosition.z += 0.05 * cos(5.0 * (distanceFromCenter - (uTime / 100.0)));
                // set positions
                gl_Position = uPMatrix * uMVMatrix * vec4(vertexPosition, 1.0);
                // varyings
                vTextureCoord = (uTextureMatrix0 * vec4(aTextureCoord, 0.0, 1.0)).xy;
                vVertexPosition = vertexPosition;
            }
        
        `);
    }

    render() {

        return (
            <>
                <div id="homeBg" className="fullHeight">

                    <div id="homeSliderPravi">
                        <div id="sliderCurrent">0<span id="sliderCurrentNumber">0</span><span id="sliderNextNumber"></span></div>
                        <div id="sliderLine">
                            <span></span>
                        </div>
                        <div id="sliderTotal">05</div>
                    </div>

                    <div id="homeSlider" className="fullHeight">

                        <div id="slide1" className="slide active fullHeight">

                            <div className="slideBg">

                                <div id="canvasSea"></div>

                                <div className="planeSea">
                                    <img src="01_cover_image_sea.jpg" alt=""/>
                                </div>

                                <div className="planeShip">
                                    <img src="01_cover_image_ships_compressed.png" alt=""/>
                                </div>

                            </div>

                            <Container className="content">
                                <Row>
                                    <Col lg={12}>
                                        <h1 className="splitTitle">
                                            <span className="indent">Глобальные</span>
                                            <span>перемены</span>
                                            <span className="indent">Марпол-2020:</span>
                                            <span className="indent">Российское</span>
                                            <span>судовое&nbsp;топливо</span>
                                            <span>с&nbsp;приставкой&nbsp;ECO</span>
                                        </h1>
                                    </Col>
                                </Row>
                            </Container>

                        </div>

                        <div id="slide2" className="slide fullHeight">

                            <div className="slideBg"></div>

                            <div id="canvasImages2" className="canvasWrapper"></div>

                            <div id="planeImages2" className="imageWrapper">
                                <div className="plane plane1">
                                    <img src="section-01/image-01.jpg" className="image_01" />
                                </div>
                                <div className="plane plane2">
                                    <img src="section-01/image-02.jpg" className="image_02" />
                                </div>
                                <div className="plane plane3">
                                    <img src="section-01/image-03.jpg" className="image_03" />
                                </div>
                            </div>

                            <Container className="content">
                                <Row>
                                    <Col lg={12}>
                                        <h2 className="splitTitle">
                                            <NavLink to={'/chapter1'}>
                                                <span>Рынок</span>
                                                <span>бункеровки</span>
                                                <span className="indent">в&nbsp;эпоху</span>
                                                <span className="indent">глобальных</span>
                                                <span className="indent minus">изменений</span>
                                            </NavLink>
                                        </h2>
                                    </Col>
                                </Row>
                            </Container>

                        </div>

                        <div id="slide3" className="slide fullHeight">

                            <div className="slideBg"></div>

                            <div id="canvasImages3" className="canvasWrapper"></div>

                            <div id="planeImages3" className="imageWrapper">
                                <div className="plane plane1">
                                    <img src="section-02/image-01.jpg" className="image_01" />
                                </div>
                                <div className="plane plane2">
                                    <img src="section-02/image-02.jpg" className="image_02" />
                                </div>
                                <div className="plane plane3">
                                    <img src="section-02/image-03.jpg" className="image_03" />
                                </div>
                            </div>

                            <Container className="content">
                                <Row>
                                    <Col lg={12}>
                                        <h2 className="splitTitle">
                                            <NavLink to={'/chapter2'}>
                                                <span>MARPOL:</span>
                                                <span className="indent">борьба&nbsp;за</span>
                                                <span>чистое&nbsp;море</span>
                                            </NavLink>
                                        </h2>
                                    </Col>
                                </Row>
                            </Container>

                        </div>

                        <div id="slide4" className="slide fullHeight">

                            <div className="slideBg"></div>

                            <div id="canvasImages4" className="canvasWrapper"></div>

                            <div id="planeImages4" className="imageWrapper">
                                <div className="plane plane1">
                                    <img src="section-03/image-01.jpg" className="image_01" />
                                </div>
                                <div className="plane plane2">
                                    <img src="section-03/image-02.jpg" className="image_02" />
                                </div>
                                <div className="plane plane3">
                                    <img src="section-03/image-03.jpg" className="image_03" />
                                </div>
                            </div>

                            <Container className="content">
                                <Row>
                                    <Col lg={12}>
                                        <h2 className="splitTitle">
                                            <NavLink to={'/chapter3'}>
                                                <span>Минимум</span>
                                                <span className="indent double">серы</span>
                                            </NavLink>
                                        </h2>
                                    </Col>
                                </Row>
                            </Container>

                        </div>

                        <div id="slide5" className="slide fullHeight">

                            <div className="slideBg"></div>

                            <div id="canvasImages5" className="canvasWrapper"></div>

                            <div id="planeImages5" className="imageWrapper">
                                <div className="plane plane1">
                                    <img src="section-04/image-01.jpg" className="image_01" />
                                </div>
                                <div className="plane plane2">
                                    <img src="section-04/image-02.jpg" className="image_02" />
                                </div>
                                <div className="plane plane3">
                                    <img src="section-04/image-03.jpg" className="image_03" />
                                </div>
                            </div>

                            <Container className="content">
                                <Row>
                                    <Col lg={12}>
                                        <h2 className="splitTitle">
                                            <NavLink to={'/chapter4'}>
                                                <span>Газовый</span>
                                                <span className="indent minus">ответ&nbsp;на</span>
                                                <span className="">нефтяные</span>
                                                <span className="">вызовы</span>
                                            </NavLink>
                                        </h2>
                                    </Col>
                                </Row>
                            </Container>

                        </div>

                        <div id="slide6" className="slide fullHeight">

                            <div className="slideBg"></div>

                            <div id="canvasImages6" className="canvasWrapper"></div>

                            <div id="planeImages6" className="imageWrapper">
                                <div className="plane plane1">
                                    <img src="section-05/image-01.jpg" className="image_01" />
                                </div>
                                <div className="plane plane2">
                                    <img src="section-05/image-02.jpg" className="image_02" />
                                </div>
                                <div className="plane plane3">
                                    <img src="section-05/image-03.jpg" className="image_03" />
                                </div>
                            </div>

                            <Container className="content">
                                <Row>
                                    <Col lg={12}>
                                        <h2 className="splitTitle">
                                            <NavLink to={'/chapter5'}>
                                                <span>Изменения&nbsp;—</span>
                                                <span className="indent">это&nbsp;путь</span>
                                                <span className="indent double">к&nbsp;развитию</span>
                                            </NavLink>
                                        </h2>
                                    </Col>
                                </Row>
                            </Container>

                        </div>

                    </div>

                    <footer>
                        <button id="about" className="hoverable">О проекте</button>
                        <button id="scrollDown" onClick={this.goNextOperation} attr-cursor="cursorDown" className="hoverable cursorDown">
                            <img src="arrow_down.svg" alt="Скрольте вниз" />
                        </button>
                    </footer>

                </div>
            </>
        )

    }
}