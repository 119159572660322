import React, {Component} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import {NavLink} from "react-router-dom";
import {Curtains, Plane} from "curtainsjs";
import {gsap, Power2, Power4} from "gsap";
import {SplitText} from "gsap/SplitText";
import ScrollTrigger from "gsap/ScrollTrigger";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";

export class Chapter4 extends Component {

    constructor(props) {
        super(props);
        this.planes = [];
        this.disableScroll = this.disableScroll.bind(this);
        this.enableScroll = this.enableScroll.bind(this);
        this.goNext = this.goNext.bind(this);
        this.startChapter = this.startChapter.bind(this);
        this.splitHomeTitle = null;
        this.curtainsImages4 = null;
    }

    componentDidMount() {

        gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

        const myThis = this;

        document.body.classList.add('chapter4');
        document.body.classList.remove('home','chapter1','chapter2','chapter3','chapter5');

        myThis.disableScroll();

        setTimeout(function () {

            //Loading
            if (document.body.classList.contains('loading')) {
                document.body.classList.remove('loading');
            }

            //Split Chapter Title
            let TL = gsap.timeline();
            myThis.splitHomeTitle = new SplitText('#chapterBegin h1.splitTitle span', {type: "chars"});

            TL.from(myThis.splitHomeTitle.chars,1, {yPercent: 120, opacity: 0, stagger: 0.025}, 0);
            TL.fromTo('#chapterPlanes4 .plane1', 2, {yPercent: 150}, {yPercent: 0, ease: Power4.easeInOut}, 0);
            TL.fromTo('#chapterPlanes4 .plane2', 2, {yPercent: -200, xPercent: -50}, {yPercent: -50, xPercent: -50, ease: Power4.easeInOut}, 0);
            TL.fromTo('#chapterPlanes4 .plane3', 2, {yPercent: 150}, {yPercent: 0, ease: Power4.easeInOut}, 0);
            TL.to('#chapterPlanes4', 0, {onComplete: function (){

                //Curtain Canvas Slides Images
                document.querySelectorAll('.canvasWrapper').forEach(function (canvasWrapper, index){

                    myThis.curtainsImages4 = new Curtains({
                        container: "chapterImages4",
                        watchScroll: false,
                        antialias: true,
                        pixelRatio: Math.min(.75, window.devicePixelRatio) // limit pixel ratio for performance
                    });

                    let imagesParams = {
                        vertexShader: myThis.vertShader(),
                        fragmentShader: myThis.fragShader(),
                        widthSegments: 30,
                        heightSegments: 20,
                        fov: 0,
                        uniforms: {
                            time: {
                                name: "uTime", // uniform name that will be passed to our shaders
                                type: "1f", // this means our uniform is a float
                                value: 0,
                            },
                        }
                    };

                    let planeImages = document.querySelectorAll("#chapterPlanes4 .plane");

                    // if we got planes to add
                    if(planeImages.length > 0) {

                        for(let i = 0; i < planeImages.length; i++) {

                            myThis.planes.push(new Plane(myThis.curtainsImages4, planeImages[i], imagesParams));

                        }

                    }

                });

                setTimeout(function (){

                    gsap.to('#chapterPlanes4', 0, {opacity: 0});
                    gsap.to('#chapterImages4', 0, {opacity: 0.5});

                    myThis.planes.forEach(function (plane, index) {

                        let perspective = 1;

                        let myInterval = setInterval(function () {
                            plane.setPerspective(perspective);
                            perspective++;
                            if (perspective > 10) {
                                clearInterval(myInterval);
                            }
                        }, 100);

                        // check if our plane is defined and use it
                        plane.onRender(() => {
                            plane.uniforms.time.value++;
                        });

                    });

                    window.addEventListener('wheel', myThis.startChapter);

                }, 500)


            }}, 2);

            /* Go To Begin */

            document.getElementById('scrollDown').addEventListener('click', function (){

                myThis.goBegin(1);

            });

            document.getElementById('scrollUp').addEventListener('click', function (){

                gsap.to(window, {duration: 2, scrollTo: 0});

            });

            /* EOF. Go To Begin */

            /* Go To Next */

            ScrollTrigger.create({
                trigger:"#chapterNext4",
                start: 'top bottom',
                onToggle: ({progress, direction, isActive}) => myThis.goNext(progress, direction, isActive),
                toggleActions:"restart none none reset"
            });

            /* EOF. Go To Next */

            /* Waves BQ */

            document.querySelectorAll('.waveBg').forEach(function (wave){

                let waveTL = gsap.timeline();

                waveTL.to(wave.querySelector('.wave1'), 1, {xPercent: -25}, 0)
                    .to(wave.querySelector('.wave2'), 1, {xPercent: 25}, 0)

                ScrollTrigger.create({
                    trigger: wave,
                    start: 'top bottom',
                    //markers: true,
                    scrub: true,
                    animation: waveTL
                });

            });

            /* UL */

            document.querySelectorAll('ul').forEach(function (ul){

                let ulTL = gsap.timeline();

                ul.querySelectorAll('li').forEach(function (li, index){
                    ulTL.fromTo(li.querySelector('.line'), 1, {width: 0}, {width: 69}, index);
                    ulTL.fromTo(li, 1, {y: 40}, {y: 0}, index);
                });

                ScrollTrigger.create({
                    trigger: ul,
                    start: 'top 75%',
                    end: 'bottom 75%',
                    //markers: true,
                    scrub: true,
                    animation: ulTL
                });

            });

            /* EOF. Waves BQ */

        }, 1000)

        // Unmount all Events in the right time
        let anchorLinks = document.querySelectorAll('a');

        anchorLinks.forEach(function (anchor) {

            anchor.addEventListener('click', function (e) {

                // Prevent problem with scrolling
                myThis.enableScroll();

                // Remove wheel listener
                window.removeEventListener('wheel', myThis.startChapter);

                // Removing all ScrollTriggers
                ScrollTrigger.getAll().forEach(t => t.kill());

            });

        });

    }

    componentWillUnmount() {

        this.curtainsImages4.dispose();

    }

    preventDefault(e) {
        e.preventDefault();
    }

    preventDefaultForScrollKeys(e) {
        // left: 37, up: 38, right: 39, down: 40,
        // spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
        var keys = {37: 1, 38: 1, 39: 1, 40: 1};
        if (keys[e.keyCode]) {
            this.preventDefault(e);
            return false;
        }
    }

    // call this to Disable Scroll
    disableScroll() {

        // modern Chrome requires { passive: false } when adding event
        var supportsPassive = false;
        try {
            window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
                get: function () { supportsPassive = true; }
            }));
        } catch(e) {}

        var wheelOpt = supportsPassive ? { passive: false } : false;
        var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

        window.addEventListener('DOMMouseScroll', this.preventDefault, false); // older FF
        window.addEventListener(wheelEvent, this.preventDefault, wheelOpt); // modern desktop
        window.addEventListener('touchmove', this.preventDefault, wheelOpt); // mobile
        window.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
    }

    // call this to Enable Scroll
    enableScroll() {

        // modern Chrome requires { passive: false } when adding event
        var supportsPassive = false;
        try {
            window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
                get: function () { supportsPassive = true; }
            }));
        } catch(e) {}

        var wheelOpt = supportsPassive ? { passive: false } : false;
        var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

        window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
        window.removeEventListener(wheelEvent, this.preventDefault, wheelOpt);
        window.removeEventListener('touchmove', this.preventDefault, wheelOpt);
        window.removeEventListener('keydown', this.preventDefaultForScrollKeys, false);
    }

    // Should we start?
    startChapter = e => {

        let chapterBegin = document.querySelector('#chapterBegin');

        if(e.deltaY > 0 && window.scrollY == 0 && !chapterBegin.classList.contains('goBegin')){

            this.goBegin(1);

        } else if(e.deltaY < 0 && window.scrollY == 0 && chapterBegin.classList.contains('goBegin')) {

            this.goBegin(-1);

        }

    }

    goBegin(direction){

        const myThis = this;

        let TL = gsap.timeline();

        let chapterBegin = document.querySelector('#chapterBegin');

        if(direction == 1) {

            window.removeEventListener('wheel', myThis.startChapter)

            TL.to('#chapterPlanes4', 0, {opacity: 1}, 0)
                .to('#chapterImages4', 0, {opacity: 0, onComplete: function (){
                        myThis.curtainsImages4.disableDrawing();
                    }}, 0)
                .to(myThis.splitHomeTitle.chars, .5, {yPercent: -100, stagger: 0.02, ease: "back"}, 0)
                .to('#chapterPlanes4 .plane1', 1.5, {yPercent: -200, ease: Power2.easeIn}, 0)
                .to('#chapterPlanes4 .plane2', 1.5, {
                    yPercent: -250,
                    xPercent: -50,
                    ease: Power4.easeIn
                }, 0.25)
                .to('#chapterPlanes4 .plane3', 1.5, {yPercent: -200, ease: Power2.easeIn}, .5)
                .to('#chapterBegin', 1.5, {yPercent: -100, ease: Power2.easeInOut, onComplete: function (){
                        chapterBegin.classList.add('goBegin')
                        myThis.enableScroll()
                        window.addEventListener('wheel', myThis.startChapter)
                    }}, 1)
                .to('#chapterFixedNumber', .5, {color: '#094F95'}, .75)
                .to('footer #goBack', .5, {color: '#094F95'}, .5)
                .to('footer #scrollDown', .5, {y: 220}, .5)
                .to('footer #scrollUp', .5, {y: 0}, 1.5)
                .to('#mainPravi svg path, #mainPravi svg polygon', .5, {fill: '#094F95'}, 2)
                .to('#mainPravi .nav-link', .5, {color: '#094F95'}, 2)
                .to('#circleCursor', .5, {backgroundColor: '#094F95'})
                .to('#circleCursor svg path', .5, {fill: '#fff'})

        } else {

            this.disableScroll();

            window.removeEventListener('wheel', myThis.startChapter)

            TL.to('#chapterBegin', 1.5, {yPercent: 0, ease: Power2.easeInOut}, 0)
                .to(myThis.splitHomeTitle.chars, .5, {yPercent: 0, stagger: 0.015, ease: "back"}, 1)
                .to('#chapterPlanes4 .plane1', 1.5, {yPercent: 0, ease: Power2.easeInOut}, .5)
                .to('#chapterPlanes4 .plane2', 1.5, {yPercent: -50, xPercent: -50, ease: Power2.easeInOut}, .75)
                .to('#chapterPlanes4 .plane3', 1.5, {yPercent: 0, ease: Power2.easeInOut}, 1)
                .to('#chapterPlanes4', 0, {opacity: 0}, 2.5)
                .to('#chapterImages4', 0, {opacity: .5, onComplete: function (){
                        chapterBegin.classList.remove('goBegin');
                        window.addEventListener('wheel', myThis.startChapter);
                        myThis.curtainsImages4.enableDrawing();
                    }}, 2.5)
                .to('#mainPravi svg path, #mainPravi svg polygon', .5, {fill: '#fff'}, .25)
                .to('#mainPravi .nav-link', .5, {color: '#fff'}, .25)
                .to('#chapterFixedNumber', .5, {color: '#fff'}, .75)
                .to('footer #goBack', .5, {color: '#fff'}, 1)
                .to('footer #scrollDown', .5, {y: 0}, 1)
                .to('footer #scrollUp', .5, {y: 220}, 1)
                .to('#circleCursor', .5, {backgroundColor: '#FFF'})
                .to('#circleCursor svg path', .5, {fill: '#094F95'})

        }

    }

    goNext(progress, direction, isActive){

        let TL = gsap.timeline();

        if(direction == 1){

            TL.to('#circleCursor', .5, {backgroundColor: '#fff'})
                .to('#circleCursor svg path', .5, {fill: '#094F95'})

        } else {

            TL.to('#circleCursor', .5, {backgroundColor: '#094F95'})
                .to('#circleCursor svg path', .5, {fill: '#fff'})

        }

    }

    fragShader(){

        return (`
                        
            precision mediump float;
            varying vec3 vVertexPosition;
            varying vec2 vTextureCoord;
            uniform sampler2D uSampler0;
            void main( void ) {
                // our texture
                gl_FragColor = texture2D(uSampler0, vTextureCoord);
            }
	    
        `);

    }

    vertShader(){

        return (`
                        
            precision mediump float;
            // default mandatory variables
            attribute vec3 aVertexPosition;
            attribute vec2 aTextureCoord;
            uniform mat4 uMVMatrix;
            uniform mat4 uPMatrix;
            // texture matrix
            uniform mat4 uTextureMatrix0;
            // custom variables
            varying vec3 vVertexPosition;
            varying vec2 vTextureCoord;
            uniform float uTime;
            void main() {
                vec3 vertexPosition = aVertexPosition;
                float distanceFromCenter = distance(vec2(vertexPosition.x, vertexPosition.y), vec2(0.5, vertexPosition.x));
                vertexPosition.z += 0.05 * cos(5.0 * (distanceFromCenter - (uTime / 100.0)));
                // set positions
                gl_Position = uPMatrix * uMVMatrix * vec4(vertexPosition, 1.0);
                // varyings
                vTextureCoord = (uTextureMatrix0 * vec4(aTextureCoord, 0.0, 1.0)).xy;
                vVertexPosition = vertexPosition;
            }
        
        `);
    }

    render() {
        return (
            <>
                <div id="chapterBg" className="fullHeight">
                    <div id="chapterBegin">

                        <div id="chapter4" className="chapterBg"></div>

                        <div id="chapterImages4" className="canvasWrapper"></div>

                        <div id="chapterPlanes4" className="imageWrapper inverse">
                            <div className="plane plane1">
                                <img src="section-04/image-01.jpg" className="image_01" />
                            </div>
                            <div className="plane plane2">
                                <img src="section-04/image-02.jpg" className="image_02" />
                            </div>
                            <div className="plane plane3">
                                <img src="section-04/image-03.jpg" className="image_03" />
                            </div>
                        </div>

                        <Container className="content">
                            <Row>
                                <Col lg={6}>
                                    <h1 className="splitTitle">
                                        <span>Газовый</span>
                                        <span className="indent minus">ответ&nbsp;на</span>
                                        <span className="">нефтяные</span>
                                        <span className="">вызовы</span>
                                    </h1>
                                </Col>
                            </Row>
                        </Container>

                    </div>
                    <div id="chapterContent">
                        <div className="container-fluid">
                            <div className="content">
                                <p>Почему же будущее судового топливо за газом, точнее – сжиженным природным газом (СПГ)? Ответ прост: СПГ — экологичнее и экономичнее. СПГ почти не содержит соединений серы, в «газовых» выхлопах на 85% меньшая концентрация соединений азота и на 20% — парникового газа, у него низкий расход в сравнении с жидкими видами топлива, он пожаробезопасен и у него более низкая цена. Так почему же до сих пор суда заправляют нефтепродуктами, а газ рассматривается лишь как отдаленная перспектива?</p>
                                <p>Как и в случае со скрубберами, повсеместному внедрению СПГ в качестве судового топлива мешает несколько серьезных факторов. Первая и основная проблема заключается в том, что суда, способные использовать в качестве топлива СПГ имеют принципиальные конструкционные отличия от тех, что плавают по морям многие годы, заправленные мазутом. Нельзя просто заправить в бак вместо нефти газ и поплыть. Переоборудование судна, если оно вообще возможно, будет стоить почти столько же, сколько и строительство нового. А поскольку строительство судов — процесс длительный, вполне логично, что в настоящее время в мире насчитывается не более 300 газотопливных судов. </p>
                                <p>Вторая проблема — отсутствие в портах необходимой береговой инфраструктуры для СПГ, которая также принципиально отличается от существующей.  Различные схемы СПГ-бункеровки, в т.ч. «берег–судно», «судно–судно» и даже «автоцистерна–судно», должны быть доступны во всех портах и развиваться соответствующими темпами.</p>
                                <div className="imagePlanes2">

                                    <div className="plane-wrapper">
                                        <div className="plane-inner">
                                            <div className="landscape-wrapper">
                                                <div className="imagePlane">
                                                    <img src="section-04/content-04/image-inner-01.jpg"
                                                         className="chapter_content_image" data-sampler="planeTexture"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <p>Другое дело, что обновление флота — процесс необратимый, поэтому динамика развития этого сегмента бункерного рынка будет неизбежно расти. По мнению экспертов, ежегодно в мире будет прибавляться в среднем по 255 судов, работающих на СПГ, а к 2030 году их количество вырастет до 3 тысяч. </p>
                                <p>Россия с ее колоссальными запасами природного газа, развитой системой морских и речных портов, компетенциями в области судостроения и научной базой, может стать крупнейшим игроком на рынке сжиженного природного газа как судового топлива. Российские бункерные компании это понимают и готовят обновленную стратегию развития с учетом рыночных изменений. </p>

                            </div>
                            <Row>
                                <div className="fullContent">

                                    <blockquote className="citation">
                                        <div className="waveBg">
                                            <div className="wave1"></div>
                                            <div className="wave2"></div>
                                        </div>
                                        <div className="bqContent">
                                            <div className="bqAuthor">
                                                <img className="authorPhoto" src="speaker_medvedev.png" alt="Алексей Медведев" />
                                                <p>
                                                    <span className="authorName">Алексей Медведев</span>
                                                    <span className="authorPosition">Генеральный директор <br/> «Газпромнефть Марин Бункера»</span>
                                                </p>
                                            </div>
                                            <div className="bqText">
                                                <p>СПГ-бункеровка – формирующийся рынок. Мы внимательно смотрим на это направление и видим за этим рынком будущее. Планируем его развивать и за счет этого диверсифицировать бункеровку</p>
                                            </div>
                                        </div>
                                    </blockquote>
                                </div>
                            </Row>

                            <div className="content">

                                <p>Так, «Газпром нефть», активно работающая на Балтике, считает развитие СПГ-бункеровки очень перспективным для себя направлением.  В этой связи в 2018 году по инициативе «Газпромнефть Марин Бункера» был зарегистрирован Международный стандарт ISO 20519:2017 «Суда и морские технологии. Требования к бункеровке судов, использующих СПГ в качестве топлива», а в 2019 году компания заключила соглашение о сотрудничестве в области развития и реализации СПГ-проектов с «Совкомфлотом», «Росморпортом», «Газпром газомоторное топливо» и «Объединенной судостроительной корпорацией». </p>
                                <p>Соглашение предусматривает научно-техническое сотрудничество сторон, направленное на развитие производственных мощностей для создания и строительства судов, работающих на сжиженном природном газе (СПГ) и СПГ-инфраструктуры. Кроме того, участники соглашения планируют вместе взаимодействовать с судоходными компаниями — потенциальными заказчиками судов, работающих на СПГ-топливе. Для России, которой только предстоит развивать береговую и морскую СПГ-инфраструктуру — такие договоренности очень важны. Это живой пример успешной консолидации усилий бизнеса и власти.</p>

                            </div>
                            <Row>
                                <div className="fullContent">
                                    <blockquote className="wave">
                                        <div className="waveBg">
                                            <div className="wave1"></div>
                                            <div className="wave2"></div>
                                        </div>
                                        <div className="bqContent">
                                            <div className="bqAuthor">
                                                <img className="authorPhoto" src="skf_logo.svg" alt="Совкомфлот" />
                                                <span className="authorPosition">Пресс-служба ПАО «Современный коммерческий флот» («Совкомфлот»)</span>
                                            </div>
                                            <div className="bqText">
                                                <p>Одним из центральных элементов стратегии «Совкомфлота» на период до 2025 года является «Зеленая хартия», которая закрепляет стремление компании постоянно снижать углеродный след от эксплуатации судов. В 2018 году «Совкомфлот» первым в мире внедрил СПГ в качестве основного топлива для крупнотоннажных танкеров. Двухлетний опыт успешной эксплуатации таких танкеров показал, что использование СПГ позволяет существенно снизить выбросы (углекислого газа – на 27%, оксидов азота – на 94%, сажи и оксидов серы – на 100%) по сравнению с аналогичными судами, работающими на традиционном тяжелом топливе.</p>
                                                <p>«Совкомфлот» планирует свою техническую и коммерческую политику в соответствии дорожной картой Международной морской организации (ИМО), которая предусматривает снижение выбросов углекислого газа до 2050 года на 50% по сравнению с 2008 годом. Именно поэтому компания выбрала СПГ: это наиболее экологически чистое топливо, которое доступно для широкого коммерческого внедрения уже сегодня.</p>
                                                <p>Одновременно компания изучает возможность внедрения других видов топлива и новых технологий, которые позволят уменьшить объем выбросов в атмосферу. Однако «Совкомфлот» не видит предпосылок к тому, чтобы в среднесрочной перспективе аммиак, аммоний, биодизель, водород или метанол могли стать полноценной альтернативой СПГ-топливу.</p>
                                                <p>Поэтому в обозримом будущем все новые суда, заказанные «Совкомфлотом», будут использовать в качестве основного топлива СПГ – если это не противоречит требованиям фрахтователя. Компания продолжит совершенствовать эту технологию с инженерной и операционной точек зрения. «Совкомфлот» намерен способствовать развитию инфраструктуры и рынка СПГ-топлива, выступать партнером компаний, которые планируют вывести этот вид топлива на рынок как России, так и во всем мире.</p>
                                                <p>Сотрудничество с «Газпромнефть Марин Бункер» – один из важных для всего российского судоходного рынка шагов к развитию национальной системы бункеровки судов газомоторным топливом. Масштабное внедрение СПГ в качестве экологически чистого судового топлива невозможно без наличия соответствующей инфраструктуры, включая бункеровочные базы в ключевых российских портах и специализированные бункеровочные суда. Ожидаем, что первое в России судно для СПГ-бункеровки введет в строй именно группа «Газпром».</p>
                                                <p>Одновременно с этим «Совкомфлот» по приглашению «Газпрома» принимает участие в разработке единых национальных стандартов по бункеровке судов сжиженным природным газом.</p>
                                            </div>
                                        </div>
                                    </blockquote>
                                </div>
                            </Row>
                            <div className="content">
                                <p>Также в рамках развития сегмента СПГ-бункеровки «Газпромнефть Марин Бункер» реализует проект строительства уникального для отечественной судоходной отрасли СПГ-бункеровщика «Дмитрий Менделеев». Судно грузовместимостью 5800 м³ начнет работу в Балтийском регионе уже в 2021 году.</p>
                                <p>В конце июля 2020 года случилось еще одно знаковое событие: «Газпром нефть» стала первой российской нефтяной компанией, включенной в состав участников ассоциации SGMF (Society for Gas as a Marine Fuel) — международного сообщества, имеющего консультативный статус в IMO. Членом SGMF стал оператор флота бункеровщиков «Газпромнефть Марин Бункер» — «Газпромнефть Шиппинг». Участие в работе этой авторитетной ассоциации позволит компании еще быстрее развивать инфраструктурные СПГ-проекты в России и за рубежом, совершенствовать стандарты и нормативы, а также внедрять лучшие отраслевые практики по производству, логистике и бункеровке газомоторного топлива.</p>
                                <div className="imagePlanes2">

                                    <div className="plane-wrapper">
                                        <div className="plane-inner">
                                            <div className="landscape-wrapper">
                                                <div className="imagePlane">
                                                    <img src="section-04/content-04/image-inner-02.jpg"
                                                         className="chapter_content_image" data-sampler="planeTexture"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <p>В общей же сложности прогнозов относительно мирового спроса на СПГ-бункеровку в перспективе до 2030 года огромное количество, и варьируются они довольно существенно, в диапазоне от 10 до 40 млн тонн в год. Такой разброс объясняется тем, что у рынка пока отсутствует однозначное понимание того, как будут развиваться переменные факторы: развитие инфраструктуры для производства, сжижения и бункеровки СПГ, динамика количества газотопливных судов и цена самого СПГ. Однако сомневаться в том, что сжиженный природный газ найдет дорогу на суда, оснований нет.</p>
                            </div>
                            <Row>
                                <div className="fullContent">
                                    <blockquote className="wave" style={{marginBottom: '-120px'}}>
                                        <div className="waveBg">
                                            <div className="wave1"></div>
                                            <div className="wave2"></div>
                                        </div>
                                        <div className="bqContent">
                                            <div className="bqAuthor">
                                                <img className="authorPhoto" src="speaker_buyanov.png" alt="Александр Буянов" />
                                                <span className="authorName">Александр <br/> Буянов</span>
                                                <span className="authorPosition">Заместитель генерального директора по научной работе, по развитию, экономике и экологии морского транспорта Центрального научно-исследовательского и проектно-конструкторского института морского флота, к.э.н.</span>
                                            </div>
                                            <div className="bqText">
                                                <p>«Спрос на СПГ как топлив будет однозначно расти. По данным DNV GL к 2023 г. флот бункеровщиков СПГ будет составлять уже 23 судна, из них в Европе – 28 единиц. В Роттердаме за 6 месяцев 2020 г. было забункеровано 93 тыс. м3 СПГ. Это в 3 раза больше, чем в 2019 г.</p>
                                                <p>В России ситуация с бункеровкой СПГ пока находится в стадии развития, но движение вперед есть. Об этом говорит тот факт, например, что в 2021 году будет введен в эксплуатацию первый бункеровщик СПГ компании «Газпромнефть Марин Бункер».</p>
                                                <p>Кроме того, Правительство внесло изменения в требования к внутреннему водному транспорту (Технический регламент), направленные на повышение энергоэффективности и экологичности речных судов, а также был расширен перечень видов топлива, которые можно использовать на подлежащих госрегистрации судах. Теперь помимо жидкого топлива, допускается применять природный газ. Это внушает надежды.</p>
                                                <p>Также напомню, что 9 сентября 2020 г. Приказом Росстандарта утверждены три ГОСТа, связанные с бункеровкой СПГ (ГОСТ Р 59020-2020 «Нефтяная и газовая промышленность. ГРУЗОВЫЕ ОПЕРАЦИИ И БУНКЕРОВКА СЖИЖЕННЫМ ПРИРОДНЫМ ГАЗОМ. Термины и определения»; ГОСТ Р 59021-2020 «Нефтяная и газовая промышленность. ГРУЗОВЫЕ ОПЕРАЦИИ И БУНКЕРОВКА СЖИЖЕННЫМ ПРИРОДНЫМ ГАЗОМ. Общие требования»; ГОСТ Р 59022-2020 «Нефтяная и газовая промышленность. ГРУЗОВЫЕ ОПЕРАЦИИ И БУНКЕРОВКА СЖИЖЕННЫМ ПРИРОДНЫМ ГАЗОМ. Оборудование причалов»). Эти ГОСТы могут стать основой для разработки эксплуатационных документов по бункеровки СПГ в России (судовых инструкций по бункеровки, разделов Обязательных постановлений по морским портам)». </p>
                                            </div>
                                        </div>
                                    </blockquote>
                                </div>
                            </Row>
                        </div>
                    </div>
                    <div id="chapterNext4" className="chapterNext">
                        <Container className="content">
                            <Row>
                                <Col lg={12}>
                                    <div className="nextChapterContent">
                                        <NavLink className="goLink" to={'/chapter5'}>
                                            <div className="nextChapterLabel">
                                                <span>Следующая глава</span>
                                            </div>
                                            <div className="nextChapterTitle">
                                                <span>Изменения&nbsp;—</span>
                                                <span className="indent">это&nbsp;путь</span>
                                                <span className="indent double">к&nbsp;развитию</span>
                                            </div>
                                        </NavLink>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div id="chapterFixedTitle">
                        <span>Газовый</span>
                        <span className="indent minus">ответ&nbsp;на</span>
                        <span className="">нефтяные</span>
                        <span className="">вызовы</span>
                    </div>
                    <div id="chapterFixedNumber">
                        <span>Глава 4</span>
                    </div>
                    <ul id="socialSharing">
                        <li>
                            <a className="facebook popup"
                               onClick="Share.facebook('https://marinebunker.neftegaz.ru','Глобальные перемены. Марпол-2020: Российское судовое топливо с приставкой ECO','https://marinebunker.neftegaz.ru/marinebunker_share.jpg',''); return false;">
                                <svg width="8" height="15" viewBox="0 0 8 15" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M2.22003 5.06212H0.405518V7.47414H2.22003V14.7112H5.24457V7.47414H7.44688L7.66358 5.06212H5.24457V4.05617C5.24457 3.4803 5.35967 3.25285 5.91854 3.25285H7.66358V0.237061H5.35966C3.18536 0.237061 2.22003 1.19132 2.22003 3.02023V5.06212Z"
                                          fill="#094F95"/>
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a className="twitter popup"
                               onClick="Share.twitter('https://marinebunker.neftegaz.ru','Глобальные перемены. Марпол-2020: Российское судовое топливо с приставкой ECO: https://marinebunker.neftegaz.ru/'); return false;">
                                <svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M13.6923 1.53821C13.1987 1.75636 12.6699 1.90317 12.1142 1.96933C12.6803 1.63023 13.1168 1.09365 13.3221 0.453683C12.7912 0.766945 12.2023 0.99543 11.576 1.11846C11.0763 0.586018 10.3608 0.253113 9.56969 0.253113C7.79561 0.253113 6.49123 1.9042 6.8925 3.61732C4.60829 3.50359 2.58329 2.41286 1.2281 0.754539C0.507479 1.98588 0.85483 3.59768 2.07833 4.4134C1.62833 4.39892 1.20425 4.27589 0.834092 4.07015C0.802986 5.33974 1.71647 6.52766 3.03847 6.79233C2.65172 6.89778 2.22764 6.92156 1.7963 6.83885C2.14573 7.92752 3.16082 8.71946 4.36462 8.7422C3.20852 9.6458 1.75276 10.048 0.294922 9.87739C1.51117 10.6549 2.95656 11.1087 4.50875 11.1087C9.61117 11.1087 12.4947 6.81197 12.3205 2.95668C12.8566 2.57001 13.3242 2.08719 13.6923 1.53821Z"
                                          fill="#094F95"/>
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a className="vk popup"
                               onClick="Share.vkontakte('https://marinebunker.neftegaz.ru','Глобальные перемены. Марпол-2020: Российское судовое топливо с приставкой ECO','https://marinebunker.neftegaz.ru/marinebunker_share.jpg',''); return false;">
                                <svg width="16" height="9" viewBox="0 0 16 9" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M8.44619 8.94958C8.82465 8.94958 8.98018 8.69687 8.97499 8.38097C8.95633 7.1899 9.41877 6.54879 10.2566 7.38358C11.1825 8.30847 11.3733 8.94958 12.4972 8.94958H14.488C14.9909 8.94958 15.1889 8.78801 15.1889 8.53426C15.1889 7.99776 14.3045 7.05215 13.5559 6.35719C12.5076 5.38465 12.4578 5.36083 13.3609 4.19047C14.4818 2.73737 15.9469 0.875152 14.6508 0.875152H12.1748C11.6937 0.875152 11.6594 1.14444 11.4884 1.54837C10.8693 3.00665 9.6925 4.89476 9.24561 4.60683C8.77902 4.30544 8.99262 3.11126 9.02891 1.33708C9.0372 0.868937 9.03513 0.54683 8.31866 0.381116C7.92776 0.291009 7.54619 0.253723 7.19366 0.253723C5.77937 0.253723 4.80368 0.845116 5.35737 0.948687C6.33513 1.13097 6.24181 3.24279 6.0137 4.15526C5.61658 5.74301 4.12453 2.8979 3.50345 1.48001C3.3531 1.13926 3.30748 0.875152 2.77246 0.875152H0.747458C0.441582 0.875152 0.258057 0.97458 0.258057 1.19519C0.258057 1.57012 2.09849 5.37119 3.85702 7.26654C5.572 9.11529 7.26624 8.94958 8.44619 8.94958Z"
                                          fill="#094F95"/>
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a className="ok popup"
                               onClick="Share.odnoklassniki('https://marinebunker.neftegaz.ru','Глобальные перемены. Марпол-2020: Российское судовое топливо с приставкой ECO','https://marinebunker.neftegaz.ru/marinebunker_share.jpg',''); return false;">
                                <svg width="10" height="16" viewBox="0 0 10 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M6.38136 11.191C7.18317 11.007 7.95584 10.6909 8.66818 10.2456C9.20711 9.90806 9.37011 9.19709 9.03093 8.65972C8.68968 8.11958 7.98012 7.95705 7.43772 8.29732C5.82578 9.30775 3.75051 9.30775 2.1358 8.29732C1.59686 7.95705 0.883837 8.11958 0.545357 8.65972C0.206878 9.19709 0.369182 9.90806 0.908113 10.2456C1.62114 10.6909 2.39312 11.007 3.19493 11.191L0.99204 13.3847C0.542583 13.8329 0.542583 14.5625 0.99204 15.0107C1.22024 15.2355 1.51224 15.3489 1.80772 15.3489C2.1032 15.3489 2.39867 15.2355 2.62618 15.0107L4.78815 12.855L6.95289 15.0107C7.40304 15.4623 8.13133 15.4623 8.58425 15.0107C9.03371 14.5625 9.03371 13.8329 8.58425 13.3847L6.38136 11.191ZM4.78815 7.98679C6.96121 7.98679 8.72783 6.22528 8.72783 4.06127C8.72783 1.89517 6.96121 0.133667 4.78815 0.133667C2.61508 0.133667 0.851238 1.89517 0.851238 4.06127C0.851238 6.22528 2.61508 7.98679 4.78815 7.98679ZM4.78815 2.43531C5.68775 2.43531 6.41951 3.16495 6.41951 4.06127C6.41951 4.95551 5.68775 5.68514 4.78815 5.68514C3.88854 5.68514 3.15748 4.95551 3.15748 4.06127C3.15748 3.16495 3.88854 2.43531 4.78815 2.43531Z"
                                          fill="#094F95"/>
                                </svg>
                            </a>
                        </li>
                    </ul>
                    <footer>
                        <NavLink id="goBack" className="goLink cursorLeft" to={'/chapter3'}>Назад</NavLink>
                        <button id="scrollDown" attr-cursor="cursorDown" className="hoverable cursorDown">
                            <img src="arrow_down.svg" alt="Скрольте вниз" />
                        </button>
                        <button id="scrollUp" attr-cursor="cursorUp" className="hoverable cursorUp">
                            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M30.4068 35.9573L33.1341 31.8663C35.0977 28.9573 36.5795 26.9982 37.5795 26.0027C38.2704 25.3118 38.9432 24.9482 39.6068 24.7345L39.6068 62.8618L41.3841 64.23L41.3841 24.7345C42.0522 24.9482 42.725 25.3118 43.4159 26.0027C44.4159 26.9982 45.8977 28.9573 47.8613 31.8663L50.5886 35.9573H52.225L40.7704 15.2254H40.225L28.7704 35.9573H30.4068Z" fill="#094F95"/>
                            </svg>
                        </button>
                    </footer>
                </div>
            </>
        )
    }

}